<template>
  <div class="process">
    <div class="page-title">Process</div>
    <div class="sub-title">
      Our developing process is simple and clean. It is easily customize if
      needed to fit clients needs.
    </div>
    <div class="data" v-for="d in mydata" :key="d.title">
      <div class="heading">{{ d.title }}</div>
      <p>{{ d.details }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "process",
  mounted() {
    axios.get("data/process.json").then((response) => {
      this.mydata = response.data;
    });
  },
  data() {
    return {
      mydata: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.process {
  .page-title {
    font-size: 50px;
    background: cornflowerblue;
    color: white;
    padding: 30px;
  }
  .sub-title {
    font-size: 25px;
    padding: 40px 0;
    font-style: italic;
  }
  .data {
    .heading {
      font-size: 20px;
      font-weight: bold;
      padding: 20px 0;
    }
  }
}
</style>
